$primary: #bd637e;
$cardBg: #a5bed2;

$theme-colors: (
        'primary': $primary,
);

@import '../node_modules/mdb-ui-kit/css/mdb.min.css';
@import '../node_modules/mdb-ui-kit/src/scss/mdb.free';

.hero-section {
  padding: 50px 0;
  text-align: center;

  img {
    border-radius: 50%;
    max-width: 20em;
    border: 5px solid $primary;
    margin-bottom: 20px;
  }
}

.card {
  background-color: $cardBg;
}

.timetable-section {
  padding: 1rem 1rem;
  max-width: 50rem;
}

.method {
  max-width: 50rem;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer {
  flex-shrink: 0;
}